<template>
  <div class="box">
    <el-select
      multiple
      collapse-tags
      v-model="shopId"
      placeholder="请选择"
      @change="changeShopsStatus"
    >
      <el-option
        v-for="(item,index) in shops"
        :key="index"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>

    <div class="box-toolbar">
      <div class="box-item">
        <el-select
          v-model="type"
          placeholder="请选择"
          @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in Types"
            :key="index"
            :label="item.name"
            :value="item.id"
            :disabled="item.id === 1?!Dpz:false"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-input
          placeholder="请输入菜品名称"
          v-model="product_name"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      height="67vh"
      :stripe="true"
      :data="lists"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed
        width="35"
        type="selection"
      >
      </el-table-column>
      <el-table-column
        width="80"
        label="序号"
        prop="id"
      >
      </el-table-column>
      <el-table-column
        width="120"
        label="菜品名称"
        prop="product_name"
      >
      </el-table-column>
      <el-table-column
        label="类别"
        prop="classifySelectName"
      >
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.branchShop_Id == '0'">未配置<span style="color:red">*</span></span>
          <span
            v-else-if="scope.row.is_ks_stock == false && scope.row.stock == '0'"
            style="color:red;"
          >估清</span>
          <span
            v-else-if="scope.row.is_have == 1"
            style="color:#409EFF"
          >上架中</span>
          <span
            v-else
            style="color:#FF9F3C"
          >已下架</span>
        </template>
      </el-table-column>

      <el-table-column label="店铺">
        <template slot-scope="scope">
        {{scope.row.store != 'null'?scope.row.store.bsName:''}}
        </template>
      </el-table-column>

      <el-table-column
        label="详情封面"
        prop="cover_map"
      >
        <template slot-scope="scope">
          <img
            class="shop-img"
            @click="dialog(scope.row.cover_map)"
            :src="scope.row.cover_map"
            alt=""
          >
        </template>
      </el-table-column>
      <el-table-column
        label="列表封面"
        prop="list_map"
      >
        <template slot-scope="scope">
          <img
            class="shop-img"
            @click="dialog(scope.row.list_map)"
            :src="scope.row.list_map"
            alt=""
          >
        </template>
      </el-table-column>
      <el-table-column
        label="价格"
        prop="price"
      >
      </el-table-column>
      <el-table-column
        label="横线价格"
        prop="false_price"
      >
      </el-table-column>
      <el-table-column
        label="会员折扣"
        prop="price_member"
      >
        <template slot-scope="scope">

          <span style="color:#409EFF;" v-if="scope.row.price_member">开启</span>
          <span v-if="!scope.row.price_member">关闭</span>
        </template>
      </el-table-column>
      <el-table-column
        label="库存"
        prop="stock"
      >
      <template slot-scope="scope">
        {{scope.row.is_ks_stock == 1?'不计库存':scope.row.stock}}
      </template>
      </el-table-column>
      <el-table-column
        label="规格"
        prop="flavorSelectName"
        width="300"
      >
      </el-table-column>
       <el-table-column
        label="是否外卖"
        prop="is_wm"
      >
      <template slot-scope="scope">
        <span :style="scope.row.is_wm == 1?'color:#409EFF':'color:red'">
        {{scope.row.is_wm == 1?'是':'否'}}
        </span>
      </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="editDpz && $can('admin/shop/update')"
            class="button mr-2"
            size="mini"
            type="danger"
            @click.native="handleAdminEdit(scope.row)"
            plain
          >配置</el-button>
          <el-dropdown
            trigger="click"
            v-else
          >
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/shop/update')"
                @click.native="handleAdminEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/shop/delete')"
                @click.native="handleAdminDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <div class="shop-i-bottom">
      <div>
        <el-button @click="openBatch">批量操作</el-button>
        <el-button type="primary" plain @click="openModelStore">批量修改</el-button>
      </div>
      <page
        class="shop-page"
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
    <!-- 图片显示  -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="400"
    >
      <div class="dialog-img">
        <img
          :src="dialogImgPath"
          alt=""
        >
      </div>
    </el-dialog>
    <Batch
      @refresh="refresh"
      :BatchDialog="BatchDialog"
      @openModel="closeBatch"
      :batchListArr="batchListArr"
    />
     <Stores
    :storeDialog="selectStoreDl"
    @openModel="closeBatch"
    :stores="batchListArr"
    @selectStore="selectStore"
    />
  </div>
</template>

<script>
import ShopService from '@admin/services/ShopService'
import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import Batch from './components/Batch'
import Stores from './components/Stores'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  components: {
    Page,
    Batch,
    Stores
  },
  data () {
    const Types = [
      {
        id: 0,
        name: '全部类型'
      },
      {
        id: 2,
        name: '上架中'
      },
      {
        id: 3,
        name: '已下架'
      },
      {
        id: 4,
        name: '估清'
      }
    ]
    return {
      where: {},
      Dpz: false,
      editDpz: false,
      BatchDialog: false,

      selectStoreDl: false,
      dialogVisible: false,
      dialogImgPath: '',
      product_name: '',
      loading: true,

      shopId: 0,
      shops: [],

      classifyId: 0,
      classifys: [],

      type: 0,
      Types: Types,
      // 批量所有的数据
      batchListArr: []
    }
  },
  mixins: [pagination],
  methods: {
    // 打开选择店铺
    openModelStore () {
      this.selectStoreDl = true
    },
    // 提交数据进行批量操作修改
    async selectStore (param) {
      const { data } = await flatry(ShopService.allStoreShops(param))
      if (data) {
        this.selectStoreDl = false
        this.$message.success(data.msg)
        this.searchGo()
      }
    },
    // 关闭蒙板层
    closeBatch () {
      this.BatchDialog = false
      this.selectStoreDl = false
    },
    openBatch () {
      this.BatchDialog = true
    },

    // 切换店铺 待配置的选项
    changeShopsStatus (e) {
      if (e === 0) {
        this.Dpz = false
        this.type = 0
      } else {
        this.Dpz = true
      }
      this.searchGo()
    },
    // 切换type 状态
    changeTypesStatus () {
      this.searchGo()
    },
    // 显示图片mack
    dialog (imgPath) {
      this.dialogVisible = true
      this.dialogImgPath = imgPath
    },
    // 搜索吧~
    async searchGo () {
      this.page = 1
      let param = {
        type: this.type,
        classifyId: this.classifyId,
        shopId: this.shopId,
        product_name: this.product_name
      }
      // 是否是配置中
      if (this.type === 1) {
        this.editDpz = true
      } else {
        this.editDpz = false
      }
      this.where = param
      await this.getList(param)
    },
    // 全选选中按钮
    handleSelectionChange (val) {
      let arr = {}
      arr = JSON.parse(JSON.stringify(val))
      this.batchListArr = arr
    },
    handleAdminEdit (store) {
      // 如果是配置的那么传去 当前所选的商店id
      this.$router.push({
        path: '/shop/update',
        query: { id: store.id, dpz: this.editDpz, shopId: this.shopId }
      })
    },
    handleAdminDelete (store) {
      this.$deleteDialog(`商品 ${store.product_name}`, async action => {
        if (action === 'confirm') {
          this.loading = true

          const { data } = await flatry(ShopService.delete(store.id))
          if (data) {
            this.lists.forEach((item, index) => {
              if (item.id === store.id) {
                this.lists.splice(index, 1)
              }
            })
            this.$message.success(data.msg)
          }

          this.loading = false
        }
      })
    },
    // 获取数据
    async getList () {
      this.loading = true

      let param = {
        limit: this.limit,
        page: this.page,
        where: this.where
      }

      const { data } = await flatry(ShopService.all(param))

      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false
    },
    refresh () {
      this.BatchDialog = false
      this.searchGo()
    }
  },

  async created () {
    const { data } = await flatry(ShopService.shops())
    let shop = [
      {
        id: 0,
        name: '全部店铺'
      }
    ]
    if (data) {
      this.shops = shop.concat(data.data)
    } else {
      this.shops = shop
    }
  }
}
</script>
<style lang="scss" scoped>
.el-button--danger.is-plain {
    margin-left: 10px;
}
.shop-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.shop-i-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .shop-page {
        float: right;
        margin: 0;
    }
}
</style>
