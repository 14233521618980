<template>
  <div>
    <el-dialog
      width="50%"
      :visible.sync="batch"
      @close="handleClose"
    >
      <div class="shop-batch-box">
        <el-form
          v-if="formModel"
          :model="formModel"
          ref="formModel"
          label-width="100px"
          label-suffix="："
          @submit.native.stop.prevent="handleFormSubmit('formModel')"
        >
        <el-form-item label="">
          <h3>批量修改数据</h3>

        </el-form-item>

          <el-form-item
            label="价格"
            prop="price"
          >
              <el-input
                type="text"
                v-model.trim="formModel.price"
              ></el-input>
          </el-form-item>

          <el-form-item
            label="灰色价格"
            prop="false_price"
          >
              <el-input
                type="text"
                v-model.trim="formModel.false_price"
              ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              size="medium"
              type="primary"
              native-type="submit"
              v-if="$can('admin/shop/store-shop')"
              :loading="submitLoading"
            >确定</el-button>
          </el-form-item>
          <!-- 弹出窗口内容 -->
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'stores',
  props: {
    stores: {},
    storeDialog: Boolean
  },
  data () {
    return {
      isEdit: false,
      loading: true,
      batch: this.storeDialog,
      batchListArr: [],
      submitLoading: false,
      formModel: {
        price: '',
        false_price: ''
      }
    }
  },
  watch: {
    storeDialog (val) {
      this.batch = val
    }
  },
  methods: {
    handleClose () {
      this.$emit('openModel', false)
    },
    async handleFormSubmit () {
      if (Number(this.formModel.price) < 1) {
        this.$message.warning('价格不能小于1')
        return false
      }

      let param = {
        shops: this.stores,
        price: this.formModel.price,
        false_price: this.formModel.false_price
      }
      this.$emit('selectStore', param)
      this.formModel.price = ''
      this.formModel.false_price = ''
    }

  }
}
</script>

<style lang="scss" scoped>
.shop-batch-box {
    padding: 30px;
    h3 {
        margin: 10px 0;
    }
    .shop-des {
        padding: 20px 0;
    }
    .shop-batch-bt {
        margin: 10px 0;
    }
}
</style>
