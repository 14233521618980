<template>
  <div>
    <el-dialog
      width="70%"
      :visible.sync="batch"
      @close="handleClose"
    >
      <div class="shop-batch-box">
        <h3>批量操作</h3>
        <!-- 标题与头 -->
        <el-table
          height="250"
          max-height="600"
          border
          :stripe="true"
          :data="batchListArr"
        >
          <el-table-column
            fixed
            width="80"
            type=index
          >
          </el-table-column>
          <el-table-column
            width="120"
            label="菜品名称"
            prop="product_name"
          >
            <template slot-scope="scope">
              <span v-if="!isEdit">
                {{scope.row.product_name}}
              </span>
              <el-input
                size="mini"
                placeholder="请输入内容"
                v-model="scope.row.product_name"
                v-else
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="类别"
            prop="classifySelectName"
          >
          </el-table-column>

          <el-table-column
            label="详情封面"
            prop="cover_map"
          >
            <template slot-scope="scope">
              <img
                class="shop-img"
                @click="dialog(scope.row.cover_map)"
                :src="scope.row.cover_map"
                alt=""
              >
            </template>
          </el-table-column>
          <el-table-column
            label="列表封面"
            prop="list_map"
          >
            <template slot-scope="scope">
              <img
                class="shop-img"
                @click="dialog(scope.row.list_map)"
                :src="scope.row.list_map"
                alt=""
              >
            </template>
          </el-table-column>
          <el-table-column
            label="价格"
            prop="price"
          >
            <template slot-scope="scope">
              <span v-if="!isEdit">
                {{scope.row.price}}
              </span>
              <el-input
                size="mini"
                placeholder="请输入内容"
                v-model="scope.row.price"
                v-else
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="横线价格"
            prop="false_price"
          >
            <template slot-scope="scope">
              <span v-if="!isEdit">
                {{scope.row.false_price}}
              </span>
              <el-input
                size="mini"
                placeholder="请输入内容"
                v-model="scope.row.false_price"
                v-else
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="会员折扣"
            prop="price_member"
          >
            <template slot-scope="scope">
              <span
                style="color:#409EFF;"
                v-if="scope.row.price_member"
              >开启</span>
              <span v-if="!scope.row.price_member">关闭</span>
            </template>
          </el-table-column>
          <el-table-column
            label="库存"
            prop="stock"
          >
            <template slot-scope="scope">
              <span v-if="!isEdit">
                {{scope.row.is_ks_stock == 1?'不计库存':scope.row.stock}}
              </span>
              <el-input
                size="mini"
                placeholder="请输入内容"
                v-model="scope.row.stock"
                v-else
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="规格"
            prop="flavorSelectName"
          >
          </el-table-column>

           <el-table-column
            label="是否外卖"
            prop="is_wm"
            width="115px"
          >
            <template slot-scope="scope">
              <el-switch
                disabled
                v-model="scope.row.is_wm"
                :active-text="scope.row.is_wm == 1?'是':'否'"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <!-- 底部 -->
        <div class="shop-des">共计{{batchListArr.length}}个商品</div>
        <!-- 按钮组 -->
        <div
          class="shop-batch-bt"
          v-if="batchListArr.length>=1"
        >
          <el-button
           v-if="$can('admin/shop/stock-l')"
          @click.native="stockLing">批量估清</el-button>
          <el-button
           v-if="$can('admin/shop/up-shop')"
          @click.native="upShop">批量上架</el-button>
          <el-button
           v-if="$can('admin/shop/down-shop')"
          @click.native="downShop">批量下架</el-button>
          <el-button
           v-if="$can('admin/shop/member-dis')"
          @click.native="memberDis">会员折扣</el-button>
          <el-button
           v-if="$can('admin/shop/wm')"
          @click.native="isWaimai(true)">是否外卖(是)</el-button>
           <el-button
           v-if="$can('admin/shop/wm')"
          @click.native="isWaimai(false)">是否外卖(否)</el-button>
          <el-button
           v-if="$can('admin/shop/del-shop')"
            @click.native="delShop"
            type="danger"
            plain
          >批量删除</el-button>

        </div>
        <!-- <div class="shop-batch-bt">
            <el-button type="primary" >保存操作</el-button>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ShopService from '@admin/services/ShopService'
import flatry from '@admin/utils/flatry'
const MSG = '操作成功'
export default {
  name: 'BATCH',
  props: {
    batchListArr: {},
    BatchDialog: Boolean
  },
  data () {
    return {
      isEdit: false,
      loading: true,
      batch: this.BatchDialog
    }
  },
  watch: {
    BatchDialog (val) {
      this.batch = val
    }
  },
  methods: {
    editing () {
      this.isEdit = !this.isEdit
    },
    handleClose () {
      this.$emit('openModel', false)
      //   this.$confirm('您当前是否需要保存当前更改？')
      //     .then(_ => {
      //       this.$emit('openModel', false)
      //     })
      //     .catch(_ => {
      //       this.$emit('openModel', false)
      //     })
    },
    async upShop () {
      console.log(this.batchListArr)
      console.log('全部上架')
      const { data } = await flatry(
        ShopService.upShop(this.batchListArr)
      )
      if (data) {
        this.$message.success(MSG)
        this.$emit('refresh')
      }
    },
    async downShop () {
      const { data } = await flatry(
        ShopService.downShop(this.batchListArr)
      )
      if (data) {
        this.$message.success(MSG)
        this.$emit('refresh')
      }
      console.log('全部下架')
    },
    async memberDis () {
      const { data } = await flatry(
        ShopService.memberDis(this.batchListArr)
      )
      if (data) {
        this.$message.success(MSG)
        this.$emit('refresh')
      }
      console.log('会员折扣转换')
    },
    async isWaimai (wm = true) {
      let param = {
        arr: this.batchListArr,
        is_wm: wm
      }
      const { data } = await flatry(
        ShopService.isWaiMai(param)
      )
      if (data) {
        this.$message.success(MSG)
        this.$emit('refresh')
      }
    },
    async delShop () {
      const { data } = await flatry(
        ShopService.delShop(this.batchListArr)
      )
      if (data) {
        this.$message.success(MSG)
        this.$emit('refresh')
      }
      console.log('删除一切')
    },
    // 批量估清
    async stockLing () {
      const { data } = await flatry(
        ShopService.stockL(this.batchListArr)
      )
      if (data) {
        this.$message.success(MSG)
        this.$emit('refresh')
      }
      console.log('批量估清')
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.shop-batch-box {
    h3 {
        margin: 10px 0;
    }
    .shop-des {
        padding: 20px 0;
    }
    .shop-batch-bt {
        margin: 10px 0;
    }
}
</style>
